import React, { useState, useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import type { FC } from 'react';
import "./styles.css";
import { useHistory } from 'react-router-dom';
import logo from 'src/assets/assets/img/logo1.png';
import { NavLink } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import NewsMarquee from 'src/components/NewsMarquee';
import Helmet from 'react-helmet';
import { pConfig } from 'src/config';
const EditorEnquiryForm = React.lazy(() => import('src/components/EditiorEnquiryButton/EditorEnquiryForm'));


interface HeaderProps {
    className?: string;
}


const Header: FC<HeaderProps> = ({ className, ...rest }) => {
    const { user, logout, isAuthenticated } = useAuth();
    const [isMobNavOpen, setMobNavOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const toggleMobNav = () => {
        setMobNavOpen(prev => !prev);
    }
    const closeMobNav = () => {
        setMobNavOpen(false);
    }
    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            history.push('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                variant: 'error'
            });
        }
    };

    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 200) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 200) {
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop);
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const replaceStrongWithComponent = () => {
                const strongElements = document.getElementsByTagName('strong');

                for (let i = 0; i < strongElements.length; i++) {
                    if (strongElements[i].innerText === 'Apply_Now') {
                        const container = document.createElement('div');
                        strongElements[i].parentNode?.replaceChild(container, strongElements[i]);

                        const root = createRoot(container);
                        root.render(
                           <EditorEnquiryForm className="applyNowButton-editor btn btn-sm" />);
                    }
                }
            };

            replaceStrongWithComponent();

            const observer = new MutationObserver(replaceStrongWithComponent);
            observer.observe(document.body, { childList: true, subtree: true });

            return () => observer.disconnect();
        }
    }, []);

 // Or return the header JSX if needed


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "Bangalore Study",
                            "legalName": "Learntech Edu Solution Pvt.",
                            "url": `${pConfig.siteUrl}`,
                            "logo": `${pConfig.siteUrl}home_page/images/logo.png`,
                            "foundingDate": "2009",
                            "founders": [
                                {
                                    "@type": "Person",
                                    "name": "Mansoor Ali"
                                }
                            ],
                            "address": [
                                {
                                    "@type": "PostalAddress",
                                    "addressLocality": "Bangalore",
                                    "addressRegion": "IND",
                                    "postalCode": "560069",
                                    "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block, Bangalore, India"
                                }
                            ],
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "contactType": "customer support",
                                "telephone": "[+91-9036020016]",
                                "email": "info@bangalorestudy.com"
                            },
                            "sameAs": [
                                "https://www.facebook.com/bangalorestudy/",
                                "https://twitter.com/BangaloreStudy2",
                                "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                                "https://www.instagram.com/bangalorestudy/",
                            ]
                        }
                    )}
                </script>
            </Helmet>
            <GlobalPopupEnquiry title="Get Brochure" pagename={'Brochure'} className={showScroll ? 'broucher down' : 'broucher'} />

            <NewsMarquee />
            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="social-links d-none d-md-block">
                        <a target="_blank" href="https://twitter.com/BangaloreStudy2" className="twitter"><i className="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.facebook.com/bangalorestudy" className="facebook"><i className="bi bi-facebook"></i></a>
                        <a target="_blank" href="https://instagram.com/bangalorestudy" className="instagram"><i className="bi bi-instagram"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w" className="youtube"><i className="bi bi-youtube"></i></a>
                        <a target="_blank" href="https://in.pinterest.com/bangalorestudy7608/" className="pinterest"><i className="bi bi-pinterest"></i></a>
                    </div>

                    <div className="top-links d-flex">
                        <ul>
                            <li><a className="active" href="tel:18001208696">Toll Free <i className="bi bi-phone phone-icon"></i> 1800 120 8696</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/admission">Admission</a></li>
                            <li><a href="/nri-quota">NRI Quota</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <header id="header" className="d-flex">
                <div className="container d-flex align-items-center">
                    <div className="logo me-auto"><a href="/"><img src={logo} alt="" className="img-fluid" /></a></div>

                    <nav id="navbar" className={`navbar navbar-expand-lg ${isMobNavOpen ? 'navbar-mobile' : ''}`}>

                        <ul
                            className="navbar-nav me-auto ps-lg-0 mainnav"
                            style={{ paddingLeft: '0.15rem' }}
                            onBlur={closeMobNav}
                            onClick={closeMobNav}
                        >
                            <li>
                                <NavLink to="" className='nav-link' activeClassName="active" exact>
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/universities" className='nav-link' activeClassName="active">
                                    Universities
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/colleges" className='nav-link' activeClassName="active">
                                    Colleges
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/school" className='nav-link' activeClassName="active">
                                    Schools
                                </NavLink>
                            </li>
                            <li >
                                <NavLink className="nav-link " to="/courses" activeClassName="active">Courses</NavLink>
                            </li>
                            <li>
                                <NavLink to="/exams" className='nav-link' activeClassName="active">
                                    Exams
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/blog" className='nav-link' activeClassName="active">
                                    Blogs
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/news-and-event" className='nav-link' activeClassName="active">
                                    News
                                </NavLink>
                            </li>



                            <>
                                {isMobNavOpen ?
                                    <>
                                        <li className='mobileshow'>
                                            <NavLink to="/boards" activeClassName="active">
                                                Boards
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/services" activeClassName="active">
                                                Services
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/admission" activeClassName="active">
                                                Admission
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/nri-quota" activeClassName="active">
                                                NRI Quota
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/studyabroad" activeClassName="active">
                                                Study Abroad
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/scholarship" activeClassName="active">
                                                Scholarships
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/students-speak" activeClassName="active">
                                                Student's Speak
                                            </NavLink>
                                        </li>
                                        <li className='mobileshow'>
                                            <NavLink to="/recognition-approval" activeClassName="active">
                                                Recognition And Approval
                                            </NavLink>
                                        </li>



                                    </>
                                    : ''}
                            </>

                            <li className={`dropdown ${isMobNavOpen ? "menuhide" : "show"}`} >
                                <NavLink
                                    to=""
                                    isActive={(match, location) => {
                                        if (location.pathname == '/school' || location.pathname == '/boards' || location.pathname == '/services' || location.pathname == '/recognition-approval' || location.pathname == '/students-speak' || location.pathname == '/nri-quota' || location.pathname == '/studyabroad') {
                                            return true;
                                        }


                                    }}
                                >
                                    <span className="new_hamgur">
                                        <span className="menu-line"></span><span className="menu-line"></span><span className="menu-line"></span>
                                    </span>
                                </NavLink>

                                <ul className='secondnavbar'>
                                    <li>
                                        <NavLink to="/boards" activeClassName="active">
                                            Boards
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/services" activeClassName="active">
                                            Services
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/nri-quota" activeClassName="active">
                                            NRI Quota
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/studyabroad" activeClassName="active">
                                            Study Abroad
                                        </NavLink>
                                    </li>
                                    <li>

                                        <NavLink to="/scholarship" activeClassName="active">
                                            Scholarships
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/students-speak" activeClassName="active">
                                            Student's Speak
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/recognition-approval" activeClassName="active">
                                            Recognition And Approval
                                        </NavLink>
                                    </li>

                                </ul>
                            </li>
                            {isAuthenticated ?

                                <li className="dropdown">
                                    <NavLink to="">
                                        <span className="new_hamgur">
                                            Welcome {user.name} !
                                        </span>
                                    </NavLink>
                                    <ul className='secondnavbar'>

                                        {user.isadmin ? <NavLink to="app/dashboard" activeClassName='' >
                                            Dashboard
                                        </NavLink> : ''}
                                        <NavLink to="#" onClick={handleLogout} activeClassName='' >
                                            Logout
                                        </NavLink></ul>
                                </li> : <li className="nav-item"><a className="nav-link" href="/login">Login</a></li>}
                            {isMobNavOpen ?
                                <>
                                    <li className='mobileshow'>
                                        <p>&nbsp;</p>
                                    </li>
                                </>
                                : ''}
                        </ul>

                        <i className={`navbar ${isMobNavOpen ? 'bi mobile-nav-toggle bi-x' : 'bi bi-list mobile-nav-toggle'}`} onClick={toggleMobNav}></i>
                    </nav>
                </div>
            </header>
        </>
    )
}
export default Header;