import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';

import logo from 'src/assets/assets/img/logo1.png';


import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Button from 'react-bootstrap/Button';
import ManagementCourseBG from 'src/assets/BdsKarnataka/banner1.jpg';
import Navbar0 from '../Navbar';
import Footer from '../Footer';


import Sri from 'src/assets/BdsKarnataka/Sri.png';

import Ramaiah from 'src/assets/BdsKarnataka/Ramaiah.png';
import Yenepoya from 'src/assets/BdsKarnataka/Yenepoya.png';
import Vydehi from 'src/assets/BdsKarnataka/Vydehi.png';
import RajaRajeswari from 'src/assets/BdsKarnataka/RajaRajeswari.png';
import Oxford from 'src/assets/BdsKarnataka/Oxford.png';




import Manipal from 'src/assets/BdsKarnataka/Manipal.png';
import Shetty from 'src/assets/BdsKarnataka/Shetty.png';
import JSS from 'src/assets/BdsKarnataka/JSS.png';
import KLE from 'src/assets/BdsKarnataka/KLE.png';
import SDM from 'src/assets/BdsKarnataka/SDM.png';
import Dayananda from 'src/assets/BdsKarnataka/Dayananda.png';
import Hassle from 'src/assets/BdsKarnataka/Hassle.png';
import Assistance from 'src/assets/BdsKarnataka/Assistance.png';
import Step from 'src/assets/BdsKarnataka/Step.png';
import Guidance from 'src/assets/BdsKarnataka/Guidance.png';
import Exclusive from 'src/assets/BdsKarnataka/Exclusive.png';
import Stay from 'src/assets/BdsKarnataka/Stay.png';







import Increased from 'src/assets/BdsKarnataka/Increased.png';
import Low from 'src/assets/BdsKarnataka/Low.png';
import Renowned from 'src/assets/BdsKarnataka/Renowned.png';


import contactbanner from 'src/assets/BdsKarnataka/Bottom-Banner.jpg';
import middilebanner from 'src/assets/BdsKarnataka/Middle-Banner.jpg';


import "./styles.css";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BdsKarnataka: FC = () => {
  const navLinks = [
    { label: 'Home', id: 'home' },
    { label: 'Why BDS Merit Seat', id: 'about' },
    { label: 'Merit List Details', id: 'details' },
    { label: 'Cut-offs', id: 'cutoff' },
    { label: 'How We Help', id: 'help' },
    { label: 'Top Dental Colleges', id: 'topcolleges' },
    { label: 'FAQs', id: 'FAQs' },
    { label: 'Contact Us', id: 'Contactus' },

  ];

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'BDS Merit Seat PPC');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>BDS 2023-24 Merit Seat Guidance for Karnataka Students</title>
        <meta name="description"
          content="Made it to the Karnataka merit list NEET-UG 2023? Avail our expert assistance and get into Karnataka’s top dental colleges with a BDS government quota seat. Enquire now!
          " />
        <link rel="canonical" href="https://bangalorestudy.com/bds-merit-seat-guidance" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "BDS Merit Seat PPC",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "Who conducts the Karnataka BDS Counselling Process?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Karnataka Examinations Authority (KEA) conducts the BDS Counselling process every year for BDS admissions in Karnataka."
                  }
                }, {
                  "@type": "Question",
                  "name": "Who releases the Karnataka Merit List NEET-UG 2023?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Karnataka Examinations Authority (KEA) will release the NEET-UG Karnataka state merit list 2023."
                  }
                }, {
                  "@type": "Question",
                  "name": " When will the KEA NEET merit list for BDS be released?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The NEET-UG Karnataka state merit list 2023 will be released after KEA’s NEET document verification in Karnataka and before commencement of the first round of KEA BDS 2023-24 counselling."
                  }
                }, {
                  "@type": "Question",
                  "name": "Should students participate in KEA NEET Counselling to acquire a BDS merit seat?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, students need to participate in the KEA NEET counselling to get a BDS merit seat."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BDS Government Quota NEET-UG Cut-off for colleges in Karnataka?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BDS government quota NEET-UG cut-off varies every year. It is higher than the BDS management quota seat cut-off."
                  }
                }
                ]
            }
          )}
        </script>


      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 bamleftsec" >
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar0 logo={logo}
          navLinks={navLinks}

        />
        {/* <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="BAMS Admission in Karnataka 2023-24"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav my-3">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'about'))}>Why Us?</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'details'))}>MBBS Details</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Scope'))}>Scope</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'admission'))}> Admissions</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'topcolleges'))}>Top Medical  Colleges</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'FAQs'))}>FAQs</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
      </header>

      <section className="d-flex align-items-center pb-0 px-0" id="home" style={{ paddingTop: 83 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bamsimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4 "
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblackmbbs slide-in'>

                  <h1 className='text-center h1amb  pt-3 '>BDS 2023-24 Merit Seat Guidance
                    for Karnataka Students

                    {/* <span className='d-block'>BDS Admission 2023-24</span > */}
                    {/* <span className='span pt-2'></span> */}
                  </h1>

                  <p className='first-bds mt-3  pt-md-4 mt-md-2'>Top Colleges, Low Fee Packages</p>
                  <p className='text-center p pt-md-4 mt-3  mt-md-2 font23 '  >Cleared NEET-UG 2023? Are You a Student from Karnataka who
                    <br className='d-none d-md-block' /> Made it to the Karnataka Merit List NEET-UG 2023? Here’s Your
                    <br className='d-none d-md-block' /> Chance for Securing Your Dream Karnataka NEET-UG Merit Seat
                    <br className='d-none d-md-block' /> for BDS in the state’s Top Dental Colleges!

                  </p>

                  <Button variant="primary" className="btn btn-success mb-4 btn-bds-add" onClick={handleShow}>
                    Connect with our Experts!
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-boxamb p-md-4 slide-in2 py-md-2">
                  <div>

                    <h3
                      className="f700 pb-2"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Assistance for Karnataka Students in BDS Government Quota Merit Seats.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Location"
                        required
                        className="form-control"
                      />

                    </div>

                    {/* <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">Location</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div> */}

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" >
                      {/* <span  style={{ display: "hidden" }}></span> */}
                      <button className="btn btn-success submit btn-bds-addcolour" name="submit" id="about" defaultValue="Submit" type="submit">Submit  </button>

                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >

        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 bds-add-h2 h2 pb-0 pb-md-4 pb-md-0' >Why BDS Government Quota Merit Seats


            </div>
            <div className="col-12 col-md-4">     <div style={{ textAlign: "center" }}>
              {/* <i
        className="fa fa-bars"
        style={{ fontSize: 100, marginBottom: 17 }}
      /> */}
              <img src={Increased} className='bamsimg4' alt='Direct Admission in Top Dental Colleges in Karnataka'></img>
              <h3 className='mabbsorangen' >Increased Chances of Securing Seat
              </h3>
              <p className='pt-3 pb-3 pt-md-3 pb-md-4'>Given the neck-to-neck competition for admission to courses under NEET-UG, BDS has comparatively lesser competition. Once you’ve made it to the Karnataka Merit List NEET-UG 2023, your chances of securing BDS Government Quota Merit Seats are bright!

              </p>
            </div></div>
            <div className="col-12 col-md-4">     <div style={{ textAlign: "center" }}>
              <img src={Low} className='bamsimg4' alt='Lowest BDS Course'></img>
              <h3 className='mabbsorangen' >Low BDS Course Fees</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'> Quality education doesn’t have to be expensive. BDS course fees is much more affordable than the fees for other NEET-UG courses. And a merit seat makes it even more affordable. Get in touch with us to secure a BDS Merit Seat with a fee package that is just a fraction of the average BDS fees!


              </p>
            </div></div>

            <div className="col-12 col-md-4">     <div style={{ textAlign: "center" }}>
              <img src={Renowned} className='bamsimg4' alt='Step-by-Step NEET Counselling Assistance'></img>

              <h3 className='mabbsorangen'>Renowned Dental Colleges
              </h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'  >
                Facilities and training offered in renowned Dental colleges help gain the knowledge, skills and exposure necessary to build a successful career in the field. Get into top Dental colleges, irrespective of your Karnataka State Rank NEET-UG 2023, and have a headstart in your career.
                <span id="details" style={{ display: "hidden" }}></span>


              </p>

            </div>


            </div>


          </div>

        </div>



      </section >


      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3bds-addmission'>Karnataka NEET Merit List 2023 Details</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>The Karnataka Examinations Authority (KEA) is entrusted with the responsibility of conducting the Karnataka BDS Counselling Process. Every year, KEA organizes the BDS Counselling after NEET-UG results are declared. Prior to the commencement of the KEA NEET-UG BDS 2023-24 Counselling, KEA will conduct the NEET Document Verification in Karnataka and release the NEET Rank List 2023 PDF Karnataka/ NEET Karnataka State Rank 2023 PDF.

            </p></div>


            <div className="col-lg-12  pt-4 px-0 ">
              <div className="bds-table">
                <table className="table table-bordered mb-0">
                  <tbody>

                    <tr>
                      <th scope="row">Counselling</th>
                      <td colSpan={3}>Karnataka BDS Counselling Process</td>

                    </tr>
                    <tr>
                      <th scope="row">Based on</th>
                      <td colSpan={3}>Karnataka Merit List NEET-UG 2023 released by KEA</td>

                    </tr>
                    <tr>
                      <th scope="row">Conducting Body

                      </th>
                      <td colSpan={3}>Karnataka Examinations Authority (KEA)

                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>NEET-UG 2023</td>

                    </tr>
                    <tr>
                      <th scope="row">Type of BDS Seats</th>
                      <td colSpan={3}>85% State Quota</td>

                    </tr>
                    <tr>
                      <th scope="row">BDS Counselling 2023 Date</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr>
                    <tr>
                      <th scope="row">Karnataka BDS Counselling Registration Date</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr>
                    <tr>
                      <th scope="row">Karnataka NEET Merit List 2023 Release Date</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr>
                    <tr>
                      <th scope="row">No. of BDS Government Quota Merit Seats 2023</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr><tr>
                      <th scope="row">No. of BDS Private Quota Seats 2023</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr>

                    <tr>
                      <th scope="row">No. of BDS NRI Quota Seats 2023</th>
                      <td colSpan={3}>To Be Announced</td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
            <div className="col-lg-12 text-center pt-5 pb-3"><p className='textmbbs'>KEA will release the Karnataka Merit List category-wise before commencement of the counselling. At the end of each round of counselling, KEA releases the respective Seat Allotment Result/ Round-wise Merit List. Candidates can refer to Karnataka NEET-UG previous year rank list and previous year seat matrix information that is available on the official website of KEA.


            </p></div>

          </div>



        </div>
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3" >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center ">
              <h3 className='bamsfont25'>Tie-Breaking in NEET-UG Karnataka State Merit List 2023</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs '>When two or more candidates happen to secure the same score in NEET-UG exam, the following rules are followed for tie-breaking to determine their individual ranks in the Karnataka NEET rank list.


            </p></div>
            <div className="col-lg-12  pt-3 px-0  ">
              <div className="bds-table">
                <table className="table table-bordered mb-0">
                  <tbody>

                    <tr>
                      <th scope="row " className='min-width-tableheading'>Level 1</th>
                      <td colSpan={2}>Candidates securing higher marks in Biology section of the NEET exam will be allotted a higher rank.</td>

                    </tr>
                    <tr>
                      <th scope="row" className='min-width-tableheading'>Level 2</th>
                      <td colSpan={3}>Candidates securing higher marks in Chemistry section of the NEET exam will be allotted a higher rank.</td>

                    </tr>
                    <tr>
                      <th scope="row" className='min-width-tableheading'>Level 3</th>
                      <td colSpan={3}>Candidates with fewer negative marks in the NEET exam
                        will be allotted a higher rank.
                      </td>

                    </tr>
                    <tr id="cutoff">
                      <th scope="row" className='min-width-tableheading'>Level 4</th>
                      <td colSpan={3}>Candidates who are older will be allotted a higher rank.
                      </td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>


          </div>



        </div>
      </section >




      <section className="container-fluid  pb-0 pt-5"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center  bdsscope-add">
            <div className="col-lg-12  pb-md-3">
              <h3 className='f3bds-addmission text-center'>BDS Cut-off Marks in Karnataka 2023
              </h3>
            </div>
            <div className="col-lg-12 text-center pt-3 pt-md-2 "><p className='textmbbs pb-3'>Every year, lakhs of candidates compete in the NEET-UG exam and in the NEET-UG counselling with the dream of securing a government quota seat. The BDS Cut-off varies each year and the BDS Cut-off Marks in Karnataka 2023 will be based on factors such as:

            </p>
            </div>

            <div className="col-lg-12 text-center pt-3 ">
              <div className="bds-table border-none mx-md-5 mx-0">
                <table className="table table-bordered documents">
                  <tbody>
                    <tr> </tr>
                    <tr className="d-flex flex-column  flex-md-row justify-content-center ">
                      <td className='pb-0 '>
                        <ul className='ps-3 ps-md-5 text-start '>
                          <li>Number of Candidates making it to the Karnataka NEET Merit List 2023</li>
                          <li>Number of BDS Seats Available in Karnataka for 2023-24</li>
                          <li>Number of Applicants for KEA BDS Counselling 2023-24</li>
                          <li>College and Course Preferences of Applicants</li>

                        </ul>

                      </td>


                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
            {/* <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope7} alt="scope6" />


              <p id="bdsaddmission">Cafeteria, Fitness & Athletics Centre</p>
            </div>
            <div id="BDS" className="col-lg-6 d-flex  align-items-center scope">
              <img src={Scope8} alt="scope6" />


              <p >Advanced Research Centre
              </p>

            </div> */}

            <div className="col-lg-12 text-center  "><p className='textmbbs pb-3'>Let’s look at the category-wise NEET-UG Cut-offs and number of candidates qualified in 2022 and 2023 to have an idea about the BDS 2023-24 competition.

            </p>
            </div>

            <div className="col-lg-12  pt-3 px-0 px-md-5 pb-3 ">
              <div className='bdssimpletable'>
                <table className="table table-bordered mb-0 text-center">
                  <tbody>

                    <tr className='bdsktable-header'>
                      <th scope="row">Category</th>
                      <th scope="row">NEET Cut-off 2022</th>
                      <th scope="row">No. of Candidates Qualified in 2022</th>
                      <th scope="row">NEET Cut-off 2023</th>
                      <th scope="row">No. of Candidates Qualified in 2023</th>
                    </tr>

                    <tr className='bdsktable-row'>
                      <td>General</td>
                      <td>117</td>
                      <td>8,81,402</td>
                      <td>137</td>
                      <td>10,14,372</td>
                    </tr>

                    <tr className='bdsktable-row'>

                      <td>OBC</td>
                      <td> 93</td>
                      <td> 74,458</td>
                      <td>107</td>
                      <td>88,592</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td>SC</td>
                      <td>93</td>
                      <td>26,087</td>
                      <td>107</td>
                      <td>29,918</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td>ST</td>
                      <td>93</td>
                      <td>10,565</td>
                      <td>107</td>
                      <td>12,437</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td>General PwD</td>
                      <td>105</td>
                      <td>328</td>
                      <td>121</td>
                      <td>405</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td> OBC PwD</td>
                      <td>93</td>
                      <td>160</td>
                      <td>107</td>
                      <td>179</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td> SC PwD</td>
                      <td>93</td>
                      <td>56</td>
                      <td>107</td>
                      <td>50</td>
                    </tr>
                    <tr className='bdsktable-row'>

                      <td>ST PwD</td>
                      <td>93</td>
                      <td>13</td>
                      <td>108</td>
                      <td>23</td>
                    </tr>
                    <tr className='bdsktable-row'>
                      <td>Total</td>
                      <td>-</td>
                      <td>9,93,069</td>
                      <td>-</td>
                      <td>11,45,976</td>
                    </tr>

                  </tbody>
                </table>


              </div>
            </div>
            <div className="col-lg-12 text-center pt-3 pt-md-3 pb-3"><p className='textmbbs pb-3'>The BDS government quota NEET-UG cut-off will be higher than that of the BDS management quota NEET-UG cut-off. Hence, having experts who can help you with the right strategies and top colleges will take you closer to your dream BDS government quota merit seat. Get in touch with us to know more about govt. seats in private dental colleges in Karnataka cut-off.

            </p>
            </div>

            {/* <div className=' gap-2 gap-md-3 bodrdertext mbbscard  divfont d-flex justify-content-around align-items-center mt-5  flex-column p-2 p-md-3 ' style={{ maxWidth: "1024px", margin: "auto" }}>
              <div className='text-center'>Made it to the Karnataka Merit List NEET-UG 2023 but not happy with your Karnataka State Rank NEET-UG 2023?</div>
              <div className='text-center'>Team up with us now to Improve Your Chances of BDS Government Quota Merit Seats.</div>
              <Button variant="primary" className="btn btn-success submit btnmbbs" style={{ width: 276 }} onClick={handleShow}>
                Talk to our Experts!

              </Button>

            </div> */}

            <div className="container-fluid bds-bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${middilebanner})` }}>
              {/* <div className='bds-position'>
              <Button variant="primary" className="btn btn-success  font-white23 btn-bds-addcolour " onClick={handleShow}>
                Talk to our Experts! <span id="help" style={{ display: "hidden" }}></span>
              </Button>
              </div> */}
              <div className='mt-4'>

                <h2 className='bds-font30 p-3' style={{
                  // background: "rgb(0 2 62 / 48%)",
                  borderRadius: "11px",
                  color: "black !important"
                }}>Made it to the Karnataka Merit List NEET-UG 2023 <br className='d-none d-md-block' /> but not happy with your Karnataka State Rank NEET-UG 2023?
                  <br />
                  <br />
                  Team up with us now to Improve Your Chances of <br className='d-none d-md-block' /> BDS Government Quota Merit Seats.

                </h2>
                <Button variant="primary" className="btn btn-success mt-5 mb-4 m-3 m-md-5 font-white23 btn-bds-addcolour " onClick={handleShow}>
                  Talk to our Experts! <span id="help" style={{ display: "hidden" }}></span>
                </Button>
              </div>
            </div>
          </div>

        </div >
      </section >


      <section className="container-fluid  pb-0 pt-5"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center  bdsscope-add mb-2">
            <div className="col-lg-12   pb-4 pb-md-5">
              <h3 className='f3bds-addmission text-center'>How We Help

              </h3>
            </div>

            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Hassle} alt="Scope1" />


              <p>Hassle-Free Admissions in Top Dental Colleges in Karnataka</p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Assistance} alt="scope2" />


              <p>Assistance in Securing Seats with Low Fee Packages</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Step} alt="scope3" />


              <p> Step-by-Step BDS Counselling Assistance
              </p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Guidance} alt="scope4" />


              <p>Guidance based on Individual Needs and Budget
              </p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center  scope-padd scope">

              <img src={Exclusive} alt="scope5" />


              <p> Exclusive Scholarship for BDS Students

              </p>
            </div>
            <div className="col-lg-6 d-flex   align-items-center   scope ">

              <img src={Stay} alt="scope6" />


              <p > Stay Updated with Karnataka BDS Counselling Expected Date, <span id="topcolleges" style={{ display: "hidden" }}></span>Karnataka Merit List NEET-UG 2023 release, and much more!
              </p>
            </div>
            {/* <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope7} alt="scope6" />


              <p id="bdsaddmission">Cafeteria, Fitness & Athletics Centre</p>
            </div>
            <div id="BDS" className="col-lg-6 d-flex  align-items-center scope">
              <img src={Scope8} alt="scope6" />


              <p >Advanced Research Centre
              </p>

            </div> */}





          </div>

        </div >
      </section >

      <section className="container-fluid  p-0 pb-0 pt-5" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className=' f3bds-addmission'>Top Dental Colleges in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-4 pb-3"><p className='textmbbs'>Securing a BDS Government Quota Seat in the Top Dental Colleges can be the best thing for your career in Dentistry. Check out this list of the best Dental Colleges in Karnataka and drop an enquiry to get started.



            </p></div>


          </div>
          <div className="col-lg-12 text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4 bamscolllege bdscollegeimage">


              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Manipal} alt="Manipal College of Dental Sciences (MCODS)" />
                  <div className="card-body">
                    <h5 className="card-title h100">Manipal College of Dental Sciences (MCODS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Manipal & Mangalore

                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Shetty} alt="A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)" />
                  <div className="card-body">
                    <h5 className="card-title h100">A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore

                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={JSS} alt="JSS Dental College & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title h100">JSS Dental College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mysore

                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>

              <div className="col ">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Yenepoya Dental College" />
                  <div className="card-body">
                    <h5 className="card-title h100 h100">Yenepoya Dental College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore

                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Ramaiah} alt="Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS) " />
                  <div className="card-body">
                    <h5 className="card-title ">Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS)
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Vydehi} alt="Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)
 " />
                  <div className="card-body">
                    <h5 className="card-title h100">Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Oxford} alt="The Oxford Dental College" />
                  <div className="card-body">
                    <h5 className="card-title h100">The Oxford Dental College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={KLE} alt="KLE Vishwanath Katti Institute of Dental Science" />
                  <div className="card-body">
                    <h5 className="card-title h100">KLE Vishwanath Katti Institute of Dental Science

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Belagavi
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>

              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={RajaRajeswari} alt="RajaRajeswari Dental College & Hospital (RRDCH)" />
                  <div className="card-body">
                    <h5 className="card-title h100"> RajaRajeswari Dental College & Hospital (RRDCH)
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Sri} alt="Sri Siddhartha Dental College & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title h100">Sri Siddhartha Dental College & Hospital



                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Tumkur
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={SDM} alt="SDM College of Dental Sciences & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title h100">SDM College of Dental Sciences & Hospital
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Dharwad
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Dayananda} alt="Dayananda Sagar College of Dental Sciences (DSCDS)" />
                  <div className="card-body">
                    <h5 className="card-title h100">Dayananda Sagar College of Dental Sciences (DSCDS)
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 27 }} id="FAQs">
            & Many More!</div>


        </div>
      </section >





      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2
              style={{
                color: "#1D3355",
                fontWeight: 600,
                textAlign: "center",
                paddingTop: 10
              }}
            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src="preprod/nursing_admission/assets/images/faq-img.png" alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Who conducts the Karnataka BDS Counselling Process?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The Karnataka Examinations Authority (KEA) conducts the BDS Counselling process every year for BDS admissions in Karnataka.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Who releases the Karnataka Merit List NEET-UG 2023?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The Karnataka Examinations Authority (KEA) will release the NEET-UG Karnataka state merit list 2023.
                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        When will the KEA NEET merit list for BDS be released?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The NEET-UG Karnataka state merit list 2023 will be released after KEA’s NEET document verification in Karnataka and before commencement of the first round of KEA BDS 2023-24 counselling.
                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Should students participate in KEA NEET Counselling to acquire a BDS merit seat?



                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Yes, students need to participate in the KEA NEET counselling to get a BDS merit seat.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BDS Government Quota NEET-UG Cut-off for colleges in Karnataka?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The BDS government quota NEET-UG cut-off varies every year. It is higher than the BDS management quota seat cut-off.



                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddAmb  '><h2 className='text-white p-3' style={{
            background: "rgb(0 2 62 / 48%)",
            borderRadius: "11px"
          }}>Realise Your Dream of a BDS Merit Seat Now!</h2>
            <Button variant="primary" className="btn btn-success mt-5 mb-4 m-3 m-md-5 font-white23 btn-bds-addcolour " onClick={handleShow}>
              Apply Now!
            </Button>
          </div>
        </div>


      </section>



      <Footer />
      {/* <footer className='pb-1 bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src="preprod/nursing_admission/assets/images/footer-bg.png" className="imgfooter" alt="bangalorestudy " />
              </div>


            </div>

            <div className="col-md-6 footer-left text-center pe-5" >
              <p className="font-white23 pe-5">Social Connect</p>
              <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp; &nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                </a>
              </p>

            </div>

          </div>




          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-3 d-flex justify-content-end" >
              <div><p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696 (Toll
                    Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <br />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                  </a>
                </p>



              </div>
            </div>
          </div>

          <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
            {" "}
            © 2025 Bangalorestudy.com. All Rights Reserved.
          </p>

          </div>


        </div>
        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-3 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
            <div className="app-unit col-md-7  col-md-5 ">
              <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div>
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
            </div>
            <p className="">
              {" "}
              © 2025 Bangalorestudy.com. All Rights Reserved.
            </p>
          </div>
        </div>


      </footer> */}
      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="bi bi-whatsapp my-float " />
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in the Details to Get in Touch with our Experts.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />

            <fieldset>
              <input
                type="text"
                id="location1"
                name="location"
                placeholder="Location"
                required
                value={values.location} onChange={set('location')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              {/* <small className="text-danger " /> */}
              {/* <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset> */}
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BdsKarnataka;