import React, { useState, useContext } from 'react';
import type { FC } from 'react';
import "./styles.css";
import { makeStyles } from '@material-ui/core';
import fArrowIcn from 'src/assets/img/f-arrow.svg';
import iosIcon from 'src/assets/img/dowload-on-app.png';
import AndroidIcon from 'src/assets/img/google-play.png';
import fLogo from 'src/assets/assets/img/logo2.png';

import SettingsContext from 'src/contexts/SettingsContext';
import {pConfig} from 'src/config';
import { saveAs } from 'file-saver';

interface FooterProps {
    className?: string;
}

const useStyles = makeStyles(() => ({
    root: {}
}));

const Footer: FC<FooterProps> = ({ className, ...rest }) => {
    const setCtx = useContext(SettingsContext);
    const fdata = setCtx.courseData.slice(0, 36);
    const chunks = (a, size) =>
        Array.from(
            new Array(Math.ceil(a.length / size)),
            (_, i) => a.slice(i * size, i * size + size)
        );
    

    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 300){
        setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 300){
        setShowScroll(false)
        }
    };
    
  
    
    window.addEventListener('scroll', checkScrollTop);

    const downloadBrouchure = async (): Promise<void> => {
        var oReq = new XMLHttpRequest();
    
        var URLToPDF = pConfig.baseUrl+"brochure/B-study-Brochure-2023.pdf";
    
        oReq.open("GET", URLToPDF, true);
    
        oReq.responseType = "blob";
    
        oReq.onload = function() {
        
            const file = new Blob([oReq.response], { type: 'application/pdf' });
    
            saveAs(file, "Bstudy Brochure 2023.pdf");
      
        };
    
        oReq.send();
        return;
    }

    return (
        <>
        <footer id="footer" className="fFooter" >
        
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-info">
                            <a href="/"><img src={fLogo} alt="Bangalorestudy Logo" className="img-fluid" /></a>
                            <p>Fulfilling Academic Aspirations with our Expertise in Educational Counselling.</p>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Info</h4>
                            <ul>
                                <li><img src={fArrowIcn} /> <a href="/about">About Us</a></li>
                                <li><img src={fArrowIcn} /> <a href="/team">Our Team</a></li>
                                <li><img src={fArrowIcn} /> <a href="/team">Careers</a></li>
                                <li><img src={fArrowIcn} /> <a href="/services">Services</a></li>
                                <li><img src={fArrowIcn} /> <a href="/contact">Contact Us</a></li>
                                <li><img src={fArrowIcn} /> <a href="/sitemaps">Sitemap</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Top Pages</h4>
                            <ul>
                                <li><img src={fArrowIcn} /> <a href="/blog">Blogs</a></li>
                                <li><img src={fArrowIcn} /> <a href="/nri-quota">NRI Quota</a></li>
                                <li><img src={fArrowIcn} /> <a href="/news-and-event">News And Events</a></li>
                                <li><img src={fArrowIcn} /> <a href="/students-speak">Student's Speak</a></li>
                                <li><img src={fArrowIcn} /> <a href="/recognition-approval">Recognition And Approval</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Legal</h4>
                            <ul>
                                <li><img src={fArrowIcn} /> <a href="/privacy-policy">Privacy Policy</a></li>
                                <li><img src={fArrowIcn} /> <a href="/terms-and-conditions">Terms & Conditions</a></li>
                                <li><img src={fArrowIcn} /> <a href="/cookies-policy">Cookies Policy</a></li>
                                <li><img src={fArrowIcn} /> <a href="/disclaimer">Disclaimer</a></li>
                               
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-newsletter">
                            <h4>Download our App</h4>
                            <p>Get Personalized, Regular Exam Alerts and College Recommendations.</p>
                            <div className="download-app d-inline-flex f-appa">
                                <a href="https://apps.apple.com/in/app/learntech/id1623567055" target='blank' ><img src={iosIcon} alt="App Store Icon" /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.ilearntech.app" target='blank'><img src={AndroidIcon} alt="Google Play Icon" /></a>
                            </div>
                            
                        </div>
                        <div className="row actions_spc">
                            <div className="col-lg-3 footer-links">
                                <h4><img src={require("src/assets/img/call-now.svg")} className="fcall" alt="Call Icon" /> Call us now</h4>
                                <ul className="call_ul_list">
                                    <li><a href="tel:08022454991">080-22454991, </a></li>
                                    <li> <a href="tel:08026631169">080-26631169</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 footer-links">
                                <h4>Connect with us</h4>
                                <div className="social-links mt-0 d-flex">
                                    <a target="_blank" href="https://twitter.com/BangaloreStudy2" className="twitter"><i className="fa-brands fa-x-twitter"/></a>
                                    <a target="_blank" href="https://www.facebook.com/bangalorestudy" className="facebook"><i className="bx bxl-facebook"></i></a>
                                    <a target="_blank" href="https://instagram.com/bangalorestudy" className="instagram"><i className="bx bxl-instagram"></i></a>
                                    <a target="_blank" href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w" className="youtube"><i className="bi bi-youtube"></i></a>
                                   {/*  <a target="_blank" href="https://twitter.com/BangaloreStudy2" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                                    <a target="_blank" href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-3 footer-links">
                                <a onClick={() => downloadBrouchure()} className="download_b">Download Brochure <img src={require("src/assets/img/b-download.svg")} alt="Download Icon" /> </a>
                            </div>
                        </div>
                        <div className="row">

                            {setCtx.courseData && setCtx.courseData.length ?
                                <>{
                                    chunks(fdata, 9).map(el =>
                                        <div className="col-lg-3 col-md-6 footer-links">
                                            <ul>
                                                {el.map((els) => {
                                                    let link_course = "/courses/" + els.stream_slug;
                                                    return (
                                                        <li><a href={link_course}>Top {els.stream_name} Courses</a></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </>
                                : null
                            }
                        </div>
                        

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                Copyright @ Bangalore Study 2025
                </div>     
            </div>

        </footer>
        {showScroll?<a href="#" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} 
        className="back-to-top d-flex align-items-center justify-content-center active" data-aos="fade-up"><i className="bi bi-arrow-up-short"></i></a>:''}
        <a href="https://wa.me/+919036020076" className='whatsapplink'
            target="_blank">
            <i className="bx bxl-whatsapp"></i>
        </a>
        </>
    )

}
export default Footer;