import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import './styles.css';
// import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
// import EducationCap from 'src/assets/img/education-cap.png';
// import HeadingBg from 'src/assets/img/heading-bg.png';
// import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
// import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg.jpg';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import GlobalReviewSection from 'src/components/GlobalReviewSection';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

const Breadcrumbs: FC<{ 
    collegedetail: any, 
    currentTab: string,
    setCurrentTab: (tab: string) => void
  }> = ({ collegedetail, currentTab, setCurrentTab }) => {
    //const [num, setNum] = React.useState(331231);
    const queryParams = new URLSearchParams(window.location.search);
    const admission = queryParams.get('admission');

    // const [currentTab, setCurrentTab] = useState<string>('overview');

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
      };

    // function createData(year, no_of_placements, companies_name, companies_logo, highest_package) {
    //     return { year, no_of_placements, companies_name, companies_logo, highest_package };
    // }

    useEffect(() => {
        admission ? setCurrentTab('admissions_TAB') : setCurrentTab('overview');
    }, [admission]);

    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">
                            <Tabs
                                value={currentTab}
                                onChange={handleTabsChange}
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="visible arrows tabs example"
                                className='mainexamtabs'
                                style={{ width: 'auto' }}
                            >
                                <Tab label="Overview" value="overview" />
                                
                                {
                                    collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'UG').length > 0 ?
                                        <Tab label="UG" value="ug_TAB" />
                                        : ''
                                }

                                {
                                    collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'PG').length > 0 ?
                                        <Tab label="PG" value="pg_TAB" />
                                        : ''
                                }

                                {
                                    collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'Doctorate').length > 0 ?
                                        <Tab label="Doctorate" value="doctorate_TAB" />
                                        : ''
                                }
                               

                                {
                                    collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'Diploma').length > 0 ?
                                        <Tab label="Diploma" value="diploma_TAB" />
                                        : ''
                                }

                                {
                                    collegedetail.admissions && collegedetail.admissions != '' && collegedetail.admissions != 'null' && collegedetail.admissions != '<p>null</p>' ?
                                        <Tab label="Admissions" value="admissions_TAB" />
                                        : ''
                                }

                                {
                                    collegedetail.scholarship && collegedetail.scholarship != '' && collegedetail.scholarship != 'null' && collegedetail.scholarship != '<p>null</p>' ?
                                        <Tab label="Scholarships" value="scholarships_TAB" />
                                        : ''
                                }

                                {
                                    collegedetail.placements && collegedetail.placements != '' && collegedetail.placements != 'null' && collegedetail.placements != '<p>null</p>' ?
                                        <Tab label="Placements" value="placements_TAB" />
                                        : ''
                                }
                                 <Tab label="Review" value="Reviews" />

                                <Tab label="FAQs" value="faq_TAB" />
                            </Tabs>

                            <div className="tab-content left-overviews-section bs-editor-text" id="rtab">
                                {(currentTab === 'overview' && currentTab) ?
                                    <>
                                        {/* <h4>Overview</h4> */}
                                        <div className="bs-editor-text" id="overview_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: collegedetail.about }} />
                                            <span dangerouslySetInnerHTML={{ __html: collegedetail.why_choose }} />
                                        </div>
                                    </> :
                                    null}



                                {(currentTab === 'Reviews' && currentTab) ?
                                    <>
                                        <span id='reviewTab'><GlobalReviewSection type="college" item_id={collegedetail.id?collegedetail.id:0}  /></span>
                                    </> :
                                    null}

                                {(currentTab === 'ug_TAB' && currentTab) ?
                                    <>
                                        {
                                            collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'UG').map((i, index) => (
                                                <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                    <div className="row">
                                                        <div className='col-lg-12'>
                                                            <div className="row">
                                                                <div className="col-md-10 coursestreamtag">
                                                                    <a href={`../${collegedetail.slug}/courses/${i.slug}`}><h5>{i.course.course_stream_name}</h5></a>
                                                                    <div className="bs-course-detail-label">
                                                                        <span>{i.duration}</span>
                                                                        <span className="bs-streams">
                                                                            {
                                                                                i.coursemodes && i.coursemodes.map((cm, indexnew) => {
                                                                                    return (
                                                                                        <>
                                                                                            {indexnew == 0 ? '' + cm.modess.mode : ', ' + cm.modess.mode}
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="bs-course-cost">
                                                                        <span>₹</span><span>per year/ sem</span>
                                                                    </div>
                                                                    <a href={`../${collegedetail.slug}/courses/${i.course.course_stream_slug}`} className="bs-orange-link">See details</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">

                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6>Main streams</h6>
                                                                    <div className="bs-streams">
                                                                        <span>{i.course.streams ? '' + i.course.streams.stream_name : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                    {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Apply for this course</a> */}
                                                                    {/* <a href="#" className="bs-free-counceling enquiry-mode" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Get Free Counselling</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </> :
                                    null}
                                {(currentTab === 'pg_TAB' && currentTab) ?
                                    <>
                                        {
                                            collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'PG').map((i, index) => (
                                                <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                    <div className="row">
                                                        <div className='col-lg-12'>
                                                            <div className="row">
                                                                <div className="col-md-10 coursestreamtag">
                                                                    <a href={`../${collegedetail.slug}/courses/${i.slug}`}><h5>{i.course.course_stream_name}</h5></a>
                                                                    <div className="bs-course-detail-label">
                                                                        <span>{i.duration}</span>
                                                                        <span className="bs-streams">
                                                                            {
                                                                                i.coursemodes && i.coursemodes.map((cm, indexnew) => {
                                                                                    return (
                                                                                        <>
                                                                                            {indexnew == 0 ? '' + cm.modess.mode : ', ' + cm.modess.mode}
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="bs-course-cost">
                                                                        <span>₹</span><span>per year/ sem</span>
                                                                    </div>
                                                                    <a href={`../${collegedetail.slug}/courses/${i.course.course_stream_slug}`} className="bs-orange-link">See details</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">

                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6>Main streams</h6>
                                                                    <div className="bs-streams">
                                                                        <span>{i.course.streams ? '' + i.course.streams.stream_name : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                    {/* <a href="#" className="btn" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Apply for this course</a> */}
                                                                    {/* <a href="#" className="bs-free-counceling enquiry-mode" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Get Free Counselling</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </> :
                                    null}

                                {(currentTab === 'doctorate_TAB' && currentTab) ?
                                    <>
                                        {
                                            collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'Doctorate').map((i, index) => (
                                                <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                    <div className="row">
                                                        <div className='col-lg-12'>
                                                            <div className="row">
                                                                <div className="col-md-10 coursestreamtag">
                                                                    <a href={`../${collegedetail.slug}/courses/${i.slug}`}><h5>{i.course.course_stream_name}</h5></a>
                                                                    <div className="bs-course-detail-label">
                                                                        <span>{i.duration}</span>
                                                                        <span className="bs-streams">
                                                                            {
                                                                                i.coursemodes && i.coursemodes.map((cm, indexnew) => {
                                                                                    return (
                                                                                        <>
                                                                                            {indexnew == 0 ? '' + cm.modess.mode : ', ' + cm.modess.mode}
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="bs-course-cost">
                                                                        <span>₹</span><span>per year/ sem</span>
                                                                    </div>
                                                                    <a href={`../${collegedetail.slug}/courses/${i.course.course_stream_slug}`} className="bs-orange-link">See details</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">

                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6>Main streams</h6>
                                                                    <div className="bs-streams">
                                                                        <span>{i.course.streams ? '' + i.course.streams.stream_name : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                    {/* <a href="#" className="bs-free-counceling enquiry-mode" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Get Free Counselling</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </> :
                                    null}

                                {(currentTab === 'diploma_TAB' && currentTab) ?
                                    <>
                                        {
                                            collegedetail.courses_college && collegedetail.courses_college.filter(o => o.course_type == 'Diploma').map((i, index) => (
                                                <div id="bs-course-types" className="bs-course-detail-card bs-shadow-boxed col-lg-8">
                                                    <div className="row">
                                                        <div className='col-lg-12'>
                                                            <div className="row">
                                                                <div className="col-md-10 coursestreamtag">
                                                                    <a href={`../${collegedetail.slug}/courses/${i.slug}`}><h5>{i.course.course_stream_name}</h5></a>
                                                                    <div className="bs-course-detail-label">
                                                                        <span>{i.duration}</span>
                                                                        <span className="bs-streams">
                                                                            {
                                                                                i.coursemodes && i.coursemodes.map((cm, indexnew) => {
                                                                                    return (
                                                                                        <>
                                                                                            {indexnew == 0 ? '' + cm.modess.mode : ', ' + cm.modess.mode}
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="bs-course-cost">
                                                                        <span>₹</span><span>per year/ sem</span>
                                                                    </div>
                                                                    <a href={`../${collegedetail.slug}/courses/${i.course.course_stream_slug}`} className="bs-orange-link">See details</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">

                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6>Main streams</h6>
                                                                    <div className="bs-streams">
                                                                        <span>{i.course.streams ? '' + i.course.streams.stream_name : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <GlobalPopupEnquiry className="btn" title="Apply for this course" />
                                                                    {/* <a href="#" className="bs-free-counceling enquiry-mode" data-toggle="modal" data-target="#hs-course-form" data-value="Bachelor of Science (B.Sc) in Nursing">Get Free Counselling</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </> :
                                    null}

                                {(currentTab === 'admissions_TAB' && currentTab) ?
                                    <>
                                        <div className="bs-editor-text" id="admissions_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: collegedetail.admissions }} />
                                        </div>
                                    </> :
                                    null}

                                {(currentTab === 'scholarships_TAB' && currentTab) ?
                                    <>
                                        <div className="bs-editor-text" id="scholarships_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: collegedetail.scholarship }} />
                                        </div>
                                    </> :
                                    null}

                                {(currentTab === 'placements_TAB' && currentTab) ?
                                    <>
                                        <div className="bs-editor-text" id="placements_TAB">
                                            <span dangerouslySetInnerHTML={{ __html: collegedetail.placements }} />
                                        </div>
                                    </> :
                                    null}

                                {(currentTab === 'faq_TAB' && currentTab) ?
                                    <>
                                        <div className="" id="faq_TAB">
                                            <h5>FAQs</h5>
                                            {collegedetail.faqs && collegedetail.faqs.map((i, index) => {
                                                return (
                                                    <>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id={`panel${index}a-header`}
                                                            >
                                                                <Typography>{index + 1 + "."} {i.questions}</Typography>
                                                                {/* <Typography>{index}</Typography> */}
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    {i.answers}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                    <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                                    </>
                                                )
                                            })}

                                        </div>
                                    </> :
                                    null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;