import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/ambedkarlandingpage/logo.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/ambedkarlandingpage/banner1.png';
import Scope1 from 'src/assets/ambedkarlandingpage/Scope-1.png';
import Scope2 from 'src/assets/ambedkarlandingpage/Scope-2.png';
import Scope3 from 'src/assets/ambedkarlandingpage/Scope-3.png';
import Scope4 from 'src/assets/ambedkarlandingpage/Scope-4.png';
import Scope5 from 'src/assets/ambedkarlandingpage/Scope-5.png';
import Scope6 from 'src/assets/ambedkarlandingpage/Scope-6.png';
import Scope7 from 'src/assets/ambedkarlandingpage/Scope-7.png';
import Scope8 from 'src/assets/ambedkarlandingpage/Scope-8.png';


import Scope11 from 'src/assets/ambedkarlandingpage/Scope-11.png';
import Scope12 from 'src/assets/ambedkarlandingpage/Scope-12.png';
import Scope13 from 'src/assets/ambedkarlandingpage/Scope-13.png';
import Scope14 from 'src/assets/ambedkarlandingpage/Scope-14.png';


import contactbanner from 'src/assets/ambedkarlandingpage/BDSBanner1.png';
import missionbanner from 'src/assets/ambedkarlandingpage/BDS-1000-X-350.png';

import WithAttached from 'src/assets/ambedkarlandingpage/WithAttached.png';
import RGUHS from 'src/assets/ambedkarlandingpage/RGUHS.png';
import DCI from 'src/assets/ambedkarlandingpage/DCI.png';


import "./styles.css";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();



  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'BDS Ambedkar PPC');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>BDS Course Admission at MRADC, Bangalore</title>
        <meta name="description"
          content=" Explore all the details related to BDS Course in MR Ambedkar Dental College: Full-form, Course-Duration, Eligibility Criteria and more. Apply Now!" />
        <link rel="canonical" href="https://bangalorestudy.com/bds-mradc-admission" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "BDS Ambedkar PPC",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What is MRADC full form?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The full form of MRADC is Mathrusri Ramabai Ambedkar Dental College. The college was established in 1986 by the Anand Social & Educational Trust."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the MRADC admission process for BDS?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "BDS admissions in MRADC are based on the NEET-UG entrance exam."
                  }
                }, {
                  "@type": "Question",
                  "name": "How are MR Ambedkar Dental College Bangalore placements?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "MR Ambedkar Dental College equips students with the right skills and necessary knowledge  so that they can have a successful career after graduation."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the MR Ambedkar Dental College and Hospital address?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The MR Ambedkar Dental College and Hospital address is 1/36. Cline Road, Balaji Layout, Cooke Town, Bangalore, Karnataka - 560005."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is MR Ambedkar Dental College Bangalore ranking?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The MR Ambedkar Dental College ranking keeps getting better every year as the college offers excellent teaching staff, state-of-the-art facilities with modern pedagogy."
                  }
                }, {
                  "@type": "Question",
                  "name": "Is the MR Ambedkar Dental College review good?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, the MR Ambedkar Dental College reviews are very good. It is one of the Top Dental Colleges in Bangalore."
                  }
                }, {
                  "@type": "Question",
                  "name": "Are hostel facilities provided in MR Ambedkar Dental College Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, MR Ambedkar Dental College Bangalore hostel facilities are provided for both boys and girls."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is MR Ambedkar Dental College Bangalore cut-off?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The MR Ambedkar Dental College Bangalore cut-off varies every year."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is MR Ambedkar Dental College eligibility criteria for BDS?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BDS eligibility criteria is 50% aggregate marks (45% aggregate marks for SC/ST/OBC) in 10+2/ 2nd PUC/ Equivalent with Physics, Chemistry, Biology and English subjects. Candidates should have also qualified in NEET-UG."
                  }
                }, {
                  "@type": "Question",
                  "name": "Is the BDS admission in Bangalore based on NEET-UG score?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": " Yes, BDS admissions in Bangalore happen through NEET-UG."
                  }
                }

                ]
            }
          )}
        </script>

      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 ambleftsec" >
          <div className="mail-sec cont-sec">
            <a href="mailto:mrambedkar.admission@gmail.com">
              <span>mrambedkar.admission@gmail.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:mrambedkar.admission@gmail.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov ms-2" >
              <img
                src={logo}
                width="95"
                height="auto"
                className="d-inline-block align-top hov"
                alt="BNYS Course in S-VYASA Deemed-to-be University"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav my-3">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'about'))}>About MRADC</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Highlights'))}>Highlights & Infra</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'BDS'))}>BDS @ MRADC</Nav.Link>
                {/* <Nav.Link href="" onClick={(e => navigate(e, 'admission'))}>Admissions</Nav.Link> */}
                <Nav.Link href="" onClick={(e => navigate(e, 'whyus'))}>Why Us</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'FAQs'))}>FAQs</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0 px-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid Ambedkarimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4 "
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblackamb slide-in'>

                  <h1 className='text-center h1amb  pt-3 '>MR Ambedkar Dental College, Bangalore -
                    <span className='d-block'>BDS Admission 2023-24</span >
                    {/* <span className='span pt-2'></span> */}
                  </h1>

                  <p className='firstpamb mt-3  pt-md-4 mt-md-2'>Strong Roots, Stronger Career!</p>
                  <p className='text-center p pt-md-4 mt-3  mt-md-2 font23 '  >Study Bachelor of Dental Surgery (BDS) in MRADC,<br className='d-none d-md-block' />
                    Bangalore, one of India’s Top Dental Colleges.
                  </p>

                  <Button variant="primary" className="btn btn-success mb-4 btnbds" onClick={handleShow}>
                    Download Brochure
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-boxamb p-md-4 slide-in2 py-md-2">
                  <div>
                    <p className='text-center p m-0 pb-2 font23' >Admissions Open!
                    </p>
                    <h3
                      className="f700 pb-2"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Get Started with MRADC Admission Process 2023-24.



                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="about">
                    {/* <span  style={{ display: "hidden" }}></span> */}
                      <button className="btn btn-success submit btnbdscolour" name="submit" defaultValue="Submit" type="submit">Submit  </button>
                     
                    </div>
                  
                  </form>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-0 pt-4" style={{ color: "black" }}  >


        <div className="container-fluid " >
          <div className='row paddingamb-icon justify-content-around'>
            <div className='col-12 Ambh2 h2 ' >
              About MRADC, Bangalore


            </div>
            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>

              <img src={RGUHS} className='swamiimg4' alt='RGUHS'></img>
              <h3 className='Ambblue' >Affiliated to RGUHS, Karnataka</h3>

            </div></div>
            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>

              <img src={DCI} className='swamiimg4' alt='DCI '></img>
              <h3 className='Ambblue' >Approved by the
                Dental Council of India
              </h3>

            </div></div>
            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>
              <img src={WithAttached} className='swamiimg4' alt='WithAttached'></img>
              <h3 className='Ambblue' >With Attached Hospital</h3>

            </div></div>







          </div>
        </div>
        <div className="container textmbbs px-0">
          <div className="row justify-content-center align-items-center px-0 ">
            <div className="col-lg-12 text-center ">
              <p className='textmbbs pt-5'>Situated in the heart of the Garden City of India, the Mathrusri Ramabai Ambedkar Dental <br className='d-none d-md-block' /> College & Hospital, popularly known as MR Ambedkar Dental College, was established in the <br className='d-none d-md-block' /> year 1986 by Anand Social & Educational Trust. MRADC, Bangalore is affiliated to Rajiv Gandhi  <br className='d-none d-md-block' /> University of Health Sciences (RGUHS) and is approved by the Dental Council of India (DCI). </p>
              {/* <p className='textmbbs pt-3' >Mission</p> */}
            </div>
            <div className="col-lg-12 text-center px-0" >

              <h3 className="Ambblueh3  pt-4 pb-4"  >Mission</h3>

              <div   className="container-fluid missionimage text-center d-flex justify-content-center align-items imgh px-0" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${missionbanner})` }}>

              <span id="Highlights" style={{ display: "hidden" }}></span>
           
              </div>


              


            </div>
          </div>
        </div>



      </section >


      <section className="container-fluid  pb-0 pt-4"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center  ambscope">
            <div className="col-lg-12  pb-md-3">
              <h3 className='f3mdu text-center'>MRADC Infrastructure and Highlights
              </h3>
            </div>
            <div className="col-lg-12 text-md-center pt-3 pt-md-0 pb-3"><p className='textmbbs pb-3'>The MR Ambedkar Dental College, Bangalore, leaves no stone unturned in its efforts to offer the <br className='d-none d-md-block' /> highest quality dental education yet at affordable fee packages, making sure quality education is <br className='d-none d-md-block' /> accessible to candidates from all sections of society. The best-in-class infrastructure that the <br className='d-none d-md-block' /> college provides has contributed to excellent MR Ambedkar Dental College Bangalore rankings <br className='d-none d-md-block' /> over the years.</p>
            </div>

            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope1} alt="Scope1" />


              <p>With Attached Hospital in the Heart of the City</p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope2} alt="scope2" />


              <p>Experienced Teaching Faculty</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope3} alt="scope3" />


              <p>Best Student-Staff Ratio</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope4} alt="scope4" />


              <p>Classrooms with Latest Multimedia Aids</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope5} alt="scope5" />


              <p>Up-to-Date Library</p>
            </div>
            <div className="col-lg-6 d-flex  mt-2 mt-md-0 align-items-center scope">

              <img src={Scope6} alt="scope6" />


              <p id="bdsaddmission">Affordable Fee Structure</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope7} alt="scope6" />


              <p id="bdsaddmission">Cafeteria, Fitness & Athletics Centre</p>
            </div>
            <div  id="BDS" className="col-lg-6 d-flex  align-items-center scope">
              <img src={Scope8} alt="scope6" />

         
              <p >Advanced Research Centre     
              </p>
              
            </div>





          </div>

        </div >
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3mdu'>BDS in MRADC, Bangalore</h3>
            </div>

            <div className="col-lg-12 text-md-center pt-3 pb-3"><p className='textmbbs'>The Bachelor of Dental Surgery (BDS) course at MRADC, Bangalore, commences during July/ <br className='d-none d-md-block' /> August of every year and 100 seats are allotted every year. The MR Ambedkar Dental College, <br className='d-none d-md-block' /> Bangalore course details are mentioned below.
            </p></div>

            <div className="col-lg-12 px-0 px-md-5 ">
              <div className=" ambscopecustomborder">
                <table className="table table-bordered mb-0">
                  <tbody>

                    <tr>
                      <th scope="row">BDS Full-form</th>
                      <td colSpan={3}>Bachelor of Dental Surgery</td>

                    </tr>
                    <tr>
                      <th scope="row">Course Level</th>
                      <td colSpan={3}>Undergraduate (UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">BDS Course Duration</th>
                      <td colSpan={3}>5 Years <p>(Including 1 year Rotatory Internship)</p></td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>National Eligibility-cum-Entrance Test for Undergraduate (NEET-UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">BDS Eligibility Criteria</th>
                      <td colSpan={3} className='d-flex justify-content-center'>
                        <ul className='ps-4' style={{ listStyle: "none" }}>
                          <li>50% aggregate marks in 10+2/ 2nd PUC/ Equivalent with</li>
                          <li>Physics, Chemistry, Biology and English subjects.</li>
                        </ul>
                      </td>

                    </tr>
                    {/* <tr>
                      <th scope="row">Mode of Learning @ S-VYASA</th>
                      <td colSpan={3}>Residential</td>

                    </tr> */}
                  </tbody>
                </table>


              </div>
            </div>

            <div className=' gap-2 gap-md-3 bodrdertext backgroundcard py-3 divfont d-flex justify-content-around align-items-center mt-5 px-3 px-md-0 flex-column' style={{ maxWidth: "886px", margin: "auto" }}>
                <div className='text-center'>Start Your BDS Application Process for 2023-2024</div>
                <Button variant="primary" className="btn btn-success submit btnsyns" onClick={handleShow}>
                  Enquire Now

                </Button>

              </div>





          </div>



        </div>
      </section >

      <section className="container-fluid  pb-0 pt-5" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center ambscope ">
            <div className="col-lg-12 ">
              <h3 className='f3mdu text-center'>Why Study BDS in MRADC, Bangalore
              </h3>
            </div>
            <div className="col-lg-12 text-center  pt-3 pb-5"><p className='textmbbs'>MRADC admission process for BDS is based on the score of candidates in NEET-UG entrance <br className='d-none d-md-block' /> exam. Here are the reasons why choosing MR Ambedkar Dental College, Bangalore, to study <br className='d-none d-md-block' /> BDS can be the best decision for you! 
            </p></div>



            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope11} alt="Scope11" />


              <p>Updated Course Module</p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope12} alt="Scope12" />


              <p> Adequate On-Field Exposure in the Busiest Hospital of the City
              </p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope mt-4 mt-md-0">

              <img src={Scope13} alt="Scope13" />


              <p>Clinics for Training in Various Aspects     <span>(Tobacco Cessation Clinic, Geriatrics Clinic, etc.)</span></p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope" >

              <img  id="whyus" src={Scope14} alt="Scope14" />


              <p> Excellent Career Opportunities Right After Graduation <span  style={{ display: "hidden" }}></span></p>

            </div>


            {/* <div className="col-lg-12 text-md-center  pt-3"  ><p className='textmbbs'>MRADC admission process for BDS is based on the score of candidates in NEET-UG entrance exam.
            </p>

            </div> */}

          </div>

        </div >
      </section >














      <section className="container-fluid  p-0 pb-0 pt-5" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='f3mdu'>Why Us</h3>
            </div>

            <div className="col-lg-12 text-md-center pt-3 pb-3"><p className='textmbbs'>With each year, the competition for securing admissions in top colleges keeps increasing <br className='d-none d-md-block' /> significantly, and deserving students lose out on their chances, owing to complicated procedures <br className='d-none d-md-block' /> and tedious counselling processes. Right guidance from the right mentors can put them miles <br className='d-none d-md-block' /> 
            ahead of their peers and help them secure their desired seat in a top college.
            </p>
            
            <p className="text-center pt-3 textmbbs smallfot-amb" >Here’s how we help.</p>
            </div>
          </div>
          <div className="col-lg-12 text-center">

          <div className="choose-section customboxes  ">
              <div className="container">

                <div
                  className="choose-box-outer customboxes why_choose_us"
                  style={{ gridTemplateColumns: "auto auto", gap: "1rem 3rem" }}
                >
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Academic Counsellors with years of expertise"
                      />
                    </span>
                    <h4>25+ Years of Mentoring Students</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive Admission Guidance"
                      />
                    </span>
                    <h4>Guidance that Suits Individual Needs & Aspirations</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Helping hand in the College application process"
                      />
                    </span>
                    <h4>End-to-End Admission Assistance</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Helping hand in the College application process"
                      />
                    </span>
                    <h4>Guided College Visit Prior to Admission</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Assistance for Availing Educational Loans and Scholarships"
                      />
                    </span>
                    <h4>Exclusive Web Portal Covering Every Latest Update</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="College visit before Admission"
                      />
                    </span>

                    <h4>Guidance for Availing Educational Loans & Scholarships </h4>
                  </div>
            





                </div>
              </div>
            </div>

          </div>

          <div id="FAQs" className='bodrdertext backgroundcard py-3 divfont d-flex justify-content-around align-items-center mt-3 px-3 px-md-0 mb-3 talkdiv-mbd' >
                {/* <div>Start Your BDS Application Process for 2023-2024</div> */}
                <Button variant="primary" className="btn btn-success submit btnsyns" style={{width:200}} onClick={handleShow}>
                Talk to our Experts

                </Button>

              </div>



        </div>
      </section >

      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2
              style={{
                color: "#1D3355",
                fontWeight: 600,
                textAlign: "center",
                paddingTop: 10
              }}
            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src="preprod/nursing_admission/assets/images/faq-img.png" alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              {/* <div class=""> */}
              {/* <div class="faq-img">
                      <img src="preprod/nursing_admission/assets/images/faq.jpg "
                      alt="Helping hand in the College application process " width="100%"> 
                  </div>*/}
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is MRADC full form?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      The full form of MRADC is Mathrusri Ramabai Ambedkar Dental College. The college was established in 1986 by the Anand Social & Educational Trust.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is the MRADC admission process for BDS?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      BDS admissions in MRADC are based on the NEET-UG entrance exam.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      How are MR Ambedkar Dental College Bangalore placements?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      MR Ambedkar Dental College equips students with the right skills and necessary knowledge  so that they can have a successful career after graduation.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is the MR Ambedkar Dental College and Hospital address?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      The MR Ambedkar Dental College and Hospital address is 1/36. Cline Road, Balaji Layout, Cooke Town, Bangalore, Karnataka - 560005.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is MR Ambedkar Dental College Bangalore ranking?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      The MR Ambedkar Dental College ranking keeps getting better every year as the college offers excellent teaching staff, state-of-the-art facilities with modern pedagogy.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      Is the MR Ambedkar Dental College review good?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      Yes, the MR Ambedkar Dental College reviews are very good. It is one of the Top Dental Colleges in Bangalore.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      Are hostel facilities provided in MR Ambedkar Dental College Bangalore?



                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      Yes, MR Ambedkar Dental College Bangalore hostel facilities are provided for both boys and girls.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is MR Ambedkar Dental College Bangalore cut-off?



                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      The MR Ambedkar Dental College Bangalore cut-off varies every year.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                      What is MR Ambedkar Dental College eligibility criteria for BDS?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                      The BDS eligibility criteria is 50% aggregate marks (45% aggregate marks for SC/ST/OBC) in 10+2/ 2nd PUC/ Equivalent with Physics, Chemistry, Biology and English subjects. Candidates should have also qualified in NEET-UG.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title " >
                      Is the BDS admission in Bangalore based on NEET-UG score?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p >
                      Yes, BDS admissions in Bangalore happen through NEET-UG.



                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddAmb pe-md-5 me-md-5 '><h2 className='text-white p-3' style={{
            background: "rgb(0 2 62 / 48%)",
            borderRadius: "11px"
          }}>Pursue BDS from Karnataka’s Most Renowned Dental Institution.</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5 font-white23 btnbdscolour " onClick={handleShow}>
              Apply Now

            </Button>
          </div>
        </div>


      </section>




      <footer className='pb-1 bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src={logo} className="Ambimgfooter" alt="bangalorestudy " />
              </div>


            </div>

            <div className="col-md-6 footer-left text-center pe-5" >
              <p className="font-white23 pe-5">Social Connect</p>
              <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                </a>
              </p>

            </div>

          </div>




          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-3 d-flex justify-content-end" >
              <div><p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696 (Toll
                    Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <br />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                  </a>
                </p>



              </div>
            </div>
          </div>

          <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
            {" "}
            © 2025Bangalorestudy.com. All Rights Reserved.
          </p>

          </div>


        </div>
        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left  col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <img src={logo} className="Ambimgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex px-4">
            <div className="app-unit col-md-7  col-md-5 ">
              <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div>
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696 (Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
            </div>
            <p className="">
              {" "}
              © 2023 Bangalorestudy.com. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="bi bi-whatsapp my-float " />
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in the Details to Get Started with Admissions for BDS in MRADC, Bangalore.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BscNursing;