import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/assets/img/logo1.png';
import Defaultlogo from 'src/assets/assets/img/logo1.png';
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';


import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/mbbslandingpage/banner1.jpg';
import Mcclogo from 'src/assets/mbbslandingpage/mcclogo.png';
import Kealogo from 'src/assets/mbbslandingpage/Kealogo.png';
import Ramahia from 'src/assets/MDS/Ramaiah.png';

import Kempegowda from 'src/assets/mbbslandingpage/Kempegowda.png';
import BGS from 'src/assets/mbbslandingpage/BGS.png';
import RajaRajeswari from 'src/assets/mbbslandingpage/RajaRajeswari.png';
import Vydehi from 'src/assets/MDS/Vydehi.png';
import Dr from 'src/assets/mbbslandingpage/Dr.png';
import Sapthagiri from 'src/assets/mbbslandingpage/Sapthagiri.png';
import MVJ from 'src/assets/mbbslandingpage/MVJ.png';
import Akash from 'src/assets/mbbslandingpage/AkashInstitute.png';

import Yenepoya from 'src/assets/MDS/Yenepoya.png';
import SDM from 'src/assets/mbbslandingpage/SDM-new.png';
import AI from 'src/assets/mbbslandingpage/AI.png';
import banner from 'src/assets/mbbslandingpage/oldbanner1.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./styles.css";
import 'animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    console.log(event);
    setValidated(true);


  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', neetrank: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('neetrank', values.neetrank);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'MBBS Counselling');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleShow = () => {
    setTextmodal("Fill in the Details to Get in Touch with our Experts.");

    setShow(true)
  };

  const [textmodal, setTextmodal] = useState('');


  const handleShowNEET = () => {
    setTextmodal("Please Fill in Your Details to Receive Your Detailed Rank Prediction.");
    setShow(true)
  };



  const [expanded, setExpanded] = useState(false);

  const navigate = (id, offset = 0) => {
    const elementToView = document.getElementById(id);
    if (elementToView) {
      const offsetTop = elementToView.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth"
      });
      setExpanded(false); // Collapse the navbar after clicking any link
    } else {
      console.error("Element not found:", id);
    }
  };


  return (
    <>
      <Helmet>
        <title>NEET-UG MBBS 2025 Counselling Guidance</title>
        <meta name="description"
          content="Get to know everything about the MBBS Counselling 2025 Process in India: MCC NEET-UG Counselling 2025 and Karnataka Medical Counselling. Improve your chances of MBBS admissions with our expert MBBS counselling guidance. Contact us now!" />
        <link rel="canonical" href="https://bangalorestudy.com/mbbs-counselling" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>

        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      neetrank: $('#neetrank').val(),
      location: $('#location').val(),
      sourse: "MBBS Counselling",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // neetrank: $('#neetrank').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec ">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 ">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4 right-sec6">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        {/* <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <a href='/'><img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="NEET-UG MBBS 2023-24"
              /></a></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className='nav-cutom-yene'>
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'whychoose'))}>Why Us?</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'counselling'))}>MBBS Counselling</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'rank'))}>NEET Rank Predictor</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'topcollege'))}>Top Colleges</Nav.Link>
               
                <Nav.Link href="" onClick={(e => navigate(e, 'Contact'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}

        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="bg-body-tertiary" variant="light" expanded={expanded}>
          <Container fluid className='px-0 px-md-2'>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="MDS Admission in Karnataka 2024-25"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse className='justify-content-end' id="responsive-navbar-nav ">
              <Nav className="ml-auto">
                <Nav.Link onClick={() => { navigate('home'); }}>Home</Nav.Link>
                <Nav.Link onClick={() => { navigate('whychoose'); }}>Why Us?</Nav.Link>
                <Nav.Link onClick={() => { navigate('counselling', 70); }}>MBBS Counselling</Nav.Link>
                <Nav.Link onClick={() => { navigate('rank', 87); }}>NEET Rank Predictor</Nav.Link>
                <Nav.Link onClick={() => { navigate('topcollege', 95); }}>Top Colleges</Nav.Link>
                <Nav.Link onClick={() => { navigate('Contact', 50); }}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bgimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 4%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblack slide-in'>
                  <h1 className='text-center pt-4 h1MBBS '>Want to Glide Through the NEET-UG 2024
                    <span className='d-block h1mbs' > MBBS Counselling Process?</span >
                    <span className='span pt-4 blinking-mount-text-decrease'>Get MBBS Admission Guidance Today!</span>
                  </h1>
                  <p className='text-center p font23' >With the Help of our Expert Counsellors, you can get into the <br className='d-none d-md-block' />Best Medical Colleges in Karnataka and other States of India <br className='d-none d-md-block' />  through the KEA Medical Counselling and NEET All India <br className='d-none d-md-block' />  Counselling.
                  </p>
                  <Button variant="primary" className="btn btn-success mb-4" onClick={handleShow}>
                    Talk to an Expert Now!
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-box p-md-4 slide-in2">
                  <div>
                    <h3
                      className="f700 pb-3"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Ease the Hurdle Blocking Your Way <br className='d-none d-md-block' /> from Acquiring an MBBS Seat.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name*"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"
                        required
                        placeholder="Contact No. * "
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="neetrank"
                        type="number"
                        id="neetrank"
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andaman & Nicobar ">Andaman & Nicobar</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli ">Dadra and Nagar Haveli</option>
                        <option value="Daman & Diu ">Daman & Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu & Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Puducherry ">Puducherry</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="West Bengal ">West Bengal</option>

                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="whychoose" >
                      <button className="btn btn-success submit" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >
        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 h2why h2 pb-3' >Why Us?

            </div>
            <div className="col-12 col-md-3">
              <div style={{ textAlign: "center" }}>
                <i
                  className="fa fa-users"
                  style={{ fontSize: 100, marginBottom: 17 }}
                />
                <h3 className='cyellow' >Team of Experts</h3>
                <p className='pt-3 pb-3 pt-md-5 pb-md-4'>
                  Our team of qualified experts with decades of experience in academic counselling will provide you with professional and timely guidance to ensure that you are on the right track.
                </p>
              </div></div>
            <div className="col-12 col-md-3 pt-4 pt-md-0">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-bars"
                style={{ fontSize: 100, marginBottom: 17 }}
              />
              <h3 className='cyellow' >Simplify the Process</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'>With multiple rounds and endless procedures, the MBBS counselling process is complicated. We simplify it for you so that the hassle doesn't get in the way of your dreams.
              </p>
            </div></div>

            <div className="col-12 col-md-3 pt-4 pt-md-0">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-list-alt"
                style={{ fontSize: 100, marginBottom: 17 }}
              />

              <h3 className='cyellow'>Right Strategy</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4' >
                Make use of our years of expertise in every stage of the Karnataka Medical Counselling process/All India Medical Counselling process and get ahead of your competition with the right Guidance.
              </p>
            </div></div>
            <div className="col-12 col-md-3 pt-4 pt-md-0">  <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-bullhorn"
                style={{ fontSize: 100, marginBottom: 17 }}
              />
              <h3 className='cyellow'>Stay Updated</h3>
              <p className='pt-2 pb-3 pt-md-4 pb-md-4 pt-xl-5' >
                We keep you updated on the latest developments, news & events concerning the MBBS Counselling 2025 Dates, Karnataka MBBS Seat Matrix 2024, and so on.
              </p>
            </div></div>
          </div>

        </div>


      </section >

      <section className="container-fluid  pb-0 pt-4" id="counselling">
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center get-details-now-form">
            <div className="col-lg-12 pb-1  pb-md-4">
              <h3 className='fh3mbbs text-center'>All About the MBBS Counselling 2025 Process
              </h3>
            </div>
            <div className="col-lg-12 d-flex  flex-wrap align-items-center">
              <img src={Mcclogo} alt="mcclogo" className='img200 m-auto m-sm-0' />
              <div className="h4 text-center h4blue">MBBS All India Counselling 2025 Process
              </div>


            </div>


            <div className="col-lg-12 px-3 pb-1">

              <ul className='text-start'>
                <li>The NEET All-India Counselling is conducted by the Medical Counselling Committee (MCC).  </li>
                <li>To participate in the All India counselling, students must first clear the NEET-UG entrance exam.</li>
                <li>MCC conducts counselling to avail All India Medical Quota Seats in every medical colleges, seats in deemed universities and central universities, seats in AIIMS, JIPMER, and other government institutes.</li>
                <li>The MBBS All India Counselling 2025 will consist of 4 rounds namely:
                  <ul className='px-5'>
                    <li>Round 1</li>
                    <li>Round 2</li>
                    <li>Round 3</li>
                    <li>Stray Vacancy Round</li>
                  </ul>
                </li>
                <li>To fill the seats that are vacant at the end of the first 3 rounds, a Stray Vacancy Round is conducted.</li>
                <li>Students participating in the All-India Quota Medical Counselling may get into any college across India depending on the priority order given by the student in the Option Entry.</li>
              </ul>


            </div>

            <div className="container">
              <div className="row ">
                <div className="col-lg-12 text-center pt-md-3  pt-4">
                  <h3 className='fh3mbbs text-center MCC-font-size' >MCC MBBS 2025 Counselling Dates
                  </h3>
                </div>

                <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Every year, MCC announces the NEET All India Counselling schedule on its official website after the completion of the NEET-UG entrance exam. Multiple counselling rounds are conducted for students who wish to attain a merit seat anywhere in India. If you are one among the many looking for an MBBS seat and have queries regarding the MCC NEET-UG Counselling 2024, reach out to us right away.

                </p></div>
                <div className="col-lg-12  pt-3 px-0 px-md-5 pb-3 ">
                  <div className='table-responsive'>
                    <table className="table table-bordered mb-0 text-center mbbsimpletable ">
                      <tbody>

                        <tr>
                          <th scope="row">Process</th>
                          <th scope="row">Round 1</th>
                          <th scope="row">Round 2</th>
                          <th scope="row">Round 3</th>
                          <th scope="row">Stray Vacancy Round</th>
                        </tr>

                        <tr>
                          <td><b>Registration</b></td>
                          <td>Aug 14 - Aug 20, 2024
                            <br/>
                            (Till 12 PM)
                          </td>
                          <td>Sept 5 - Sept 10, 2024
                          <br/>
                            (Till 12 PM)

                          </td>
                          <td>Sept 26 - Oct 2, 2024
                          <br/>
                            (Till 12 PM)
                          </td>
                          <td>Oct 16 - Oct 20, 2024
                          <br/>
                            (Till 12 PM)

                          </td>
                        </tr>
                        
                        <tr>
                          <td><b>Payment</b></td>
                          <td>Aug 14 - Aug 20, 2024
                          <br/>
                            (Till 3 PM)
                          </td>
                          <td>Sept 5 - Sept 10, 2024
                          <br/>
                            (Till 3 PM)

                          </td>
                          <td>Sept 26 - Oct 2, 2024
                          <br/>
                            (Till 3 PM)
                          </td>
                          <td>Oct 16 - Oct 20, 2024
                          <br/>
                            (Till 3 PM)

                          </td>
                        </tr>
                        <tr>
                          <td><b>Choice-Filling</b></td>
                          <td>Aug 16 - Aug 20, 2024
                          <br/>
                            (Till 11:55 PM)

                          </td>
                          <td>Sept 6 - Sept 10, 2024
                          <br/>
                            (Till 11:55 PM)


                          </td>
                          <td>Sept 27 - Oct 2, 2024
                          <br/>
                            (Till 11:55 PM)

                          </td>
                          <td>Oct 17 - Oct 20, 2024
                          <br/>
                            (Till 11:55 PM)


                          </td>
                        </tr>
                        <tr>
                          <td><b>Choice-Locking</b></td>
                          <td>Aug 20, 2024
                          <br/>
                            (4 PM to 11:55 PM)
                          </td>
                          <td>Sept 10, 2024
                          <br/>
                            (4 PM to 11:55 PM)
                          </td>
                          <td>Oct 2, 2024
                          <br/>
                            (4 PM to 11:55 PM)
                          </td>
                          <td>Oct 20, 2024
                          <br/>
                            (4 PM to 11:55 PM)

                          </td>
                        </tr>
                        <tr>
                          <td><b>Seat Allotment Processing</b></td>
                          <td>Aug 21 - Aug 22, 2024
                          </td>
                          <td>Sept 11 - Sept 12, 2024
                          </td>
                          <td>Oct 3 - Oct 4, 2024
                          </td>
                          <td>Oct 21 - Oct 22, 2024
                          </td>
                        </tr>
                        <tr>
                          <td><b>Result</b></td>
                          <td><b>August 23, 2024</b>
                          </td>
                          <td><b>September 13, 2024</b>
                          </td>
                          <td><b>October 5, 2024</b>
                          </td>
                          <td><b>October 23, 2024</b>
                          </td>
                        </tr>
                        <tr>
                          <td><b>Reporting</b></td>
                          <td>Aug 24 - Aug 29, 2024
                          </td>
                          <td>Sept 14 - Sept 20, 2024
                          </td>
                          <td>Oct 6 - Oct 12, 2024
                          </td>
                          <td>Oct 24 - Oct 30, 2024
                          </td>
                        </tr>

                        <tr>
                          <td><b>Candidates Data Verification by Institutes</b></td>
                          <td>Aug 30 - Aug 31, 2024
                          </td>
                          <td>Sept 21 - Sept 22, 2024
                          </td>
                          <td>Oct 13 - Oct 15, 2024
                          </td>
                          <td>-
                          </td>
                        </tr>


                      </tbody>
                    </table>


                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-12 d-flex  flex-wrap align-items-center">
              <img src={Kealogo} alt="Kealogo" className='img200 m-auto m-sm-0' />
              <div className="h4 text-center h4blue" >Karnataka MBBS Counselling 2025 Process
              </div>
            </div>

            <div className="col-lg-12 px-3 ">

              <ul className='text-start'>
                <li>KEA NEET Counselling will begin after the declaration of the NEET-UG results.</li>
                <li>The Karnataka Examinations Authority (KEA) conducts the Karnataka Medical Counselling for MBBS seats that are available across all the government and private medical colleges in Karnataka.</li>
                <li>Karnataka MBBS counselling 2025 is conducted to fill the government quota, private quota, management quota, and NRI quota seats.</li>
                <li>Students must complete the Karnataka Medical Counselling Registration on the official website of KEA to be eligible for the KEA NEET Counselling process.
                </li>
                <li>Round 1 and Round 2 of the Karnataka MBBS Counselling 2025 process will be conducted consecutively.</li>
                <li>If seats are remaining after the 2 initial rounds, Mop-up Round and Stray Vacancy Round will be conducted to conclude the KEA MBBS Counselling 2025 process. </li>
                <li>KEA releases the Karnataka NEET-UG Mock Allotment Results for its Round 1 counselling before the announcement of the final seat allotment result. Mock allotment results give an idea as to which college a student will be allotted after they choose their list of colleges. If you have further questions regarding KCET Mock Allotment 2025 process, then get in touch with us today. </li>
              </ul>


            </div>
            <div className="col-lg-12  p-2 p-md-4 ">
              <p className='bluecolourp text-center pt-4 pt-md-0' >The most important aspect of the KEA NEET Counselling is the choice filling or option entry. In this stage of the KEA MBBS Counselling, students must enter their choice of course and the colleges of their preference to get admitted into. KEA will allot seats based on the priority list entered by students in this stage.</p>
            </div>
          </div>
        </div >
      </section >
      <section className="container-fluid p-0" id="rank" >
        <div className="container  pt-md-0">
          <div className="row">
            <div className="col-lg-12 text-center pt-3">
              <p className='textmbbs'>Lakhs of students are eligible to participate in the NEET All India Counselling every year. It is important to know how well you fare against your competitors to get an idea about the colleges you may get into.</p>
            </div>


            <div className="col-lg-12 text-center pt-3">
              <div className="" >
                <div className="widgets get-details-now-form right-widget" style={{ background: "#61ba9a" }}>
                  <h4 className="mb-2 fh3mbbs">NEET-UG 2025 Rank Predictor</h4>
                  <Row className="align-items-center  justify-content-center">

                    <span className='bluecolourp ' style={{
                      marginBottom: "15px",
                    }} > Predict your NEET-UG 2025 Rank here.</span>
                  </Row>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="align-items-center">
                      <Col sm={4} style={{ fontSize: "20px", textAlign: "center" }} className="my-1 pl-sm-3 pb-sm-3 fontweght700">
                        Out of 180 Questions

                      </Col>
                      <Col sm={4} className="my-1">
                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                          Name
                        </Form.Label>
                        <Form.Control id="inlineFormInputName" type="number" style={{ fontSize: "15px", textAlign: "center" }} placeholder="No. of Questions Attempted" required />
                      </Col>
                      <Col sm={4} className="my-1">
                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                          Name
                        </Form.Label>
                        <Form.Control id="inlineFormInputName" type="number" style={{ fontSize: "15px", textAlign: "center" }} placeholder="No. of Correct Answers" required />
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                      <Col xs="auto" className="my-1">
                        <Button variant="primary" className="btn btn-success mb-4" onClick={handleShowNEET}>
                          Submit
                        </Button>

                        <p>Note:  The prediction will be based on previous year NEET-UG results and exam analysis.</p>
                      </Col></Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' container row justify-content-center align-items-center get-details-now-form  p-0 pb-4 pt-3 gap-md-0 gap-4 text-center' style={{ backgroundColor: "white" }}>
          <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
            <div className=' textmbbs border rounded-4 p-3' style={{ backgroundColor: "#86d7f1" }}>
              The NEET-UG 2025 Rank Predictor <br className=' d-none d-md-block' /> assesses your performance in NEET-UG <br className=' d-none d-md-block' /> and predicts the rank you might secure.<br className=' d-none d-md-block' /> This can be used to get an idea about the <br className=' d-none d-md-block' /> colleges you may be eligible for and get <br className=' d-none d-md-block' /> admissions to.
            </div>
          </div>

          <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
            <div className=' textmbbs border rounded-4 p-3 ' style={{ backgroundColor: "#86d7f1" }} >
              The NEET-UG Rank Prediction gives you a <br className=' d-none d-md-block' /> headstart in the option entry stage of the <br className=' d-none d-md-block' /> All-India MBBS Counselling 2025 and helps <br className=' d-none d-md-block' /> in strategizing choices to improve your <br className=' d-none d-md-block' /> chances of securing admission in your dream <br className=' d-none d-md-block' />
              college!
            </div>

          </div>
        </div>
      </section>

      <section className="container-fluid  p-0 pb-0" id="topcollege" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3mbbs'>Best Medical Colleges in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Students who appear for the All India Medical Counselling are eligible to get into any of the top Medical colleges in India. Students can also take part in the KEA Counselling process after clearing NEET-UG to get a seat in one of the best MBBS colleges in Karnataka.


              <br />  <br />With that in mind, aspirants can go through the list of colleges mentioned below to get an idea on the colleges to prioritise during the Choice Filling round of the All India MBBS Counselling 2025 or the KEA MBBS Counselling 2024.</p></div>
          </div>
          <div className="col-lg-12 text-center mbbs-conselling">
            <div className="row row-cols-1 row-cols-md-4 g-4 landgingcolleges bdslandgingcolleges bdscollegeimage mds">


              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Ramahia} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Ramaiah Medical College (RMC)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Kempegowda} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Kempegowda Institute of Medical Sciences (KIMS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={BGS} alt="BGS Global Institute of Medical Sciences" />
                  <div className="card-body">
                    <h5 className="card-title">BGS Global Institute of Medical Sciences</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={RajaRajeswari} alt="RajaRajeswari" />
                  <div className="card-body">
                    <h5 className="card-title">RajaRajeswari Medical College & Hospital </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Vydehi} alt="Vydehi Institute of Medical Sciences & Research Centre " />
                  <div className="card-body">
                    <h5 className="card-title linewidth">Vydehi Institute of Medical Sciences & Research Centre </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Dr} alt="Dr. B.R. Ambedkar Medical College" />
                  <div className="card-body">
                    <h5 className="card-title linewidth">Dr. B.R. Ambedkar Medical College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Sapthagiri} alt="Sapthagiri Institute of Medical Sciences & Research Centre (SIMSRC)" />
                  <div className="card-body">
                    <h5 className="card-title linewidth">Sapthagiri Institute of Medical Sciences & Research Centre (SIMSRC)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={MVJ} alt="MVJ Medical College & Research Hospital " />
                  <div className="card-body">
                    <h5 className="card-title linewidth">M.V.J Medical College & Research Hospital </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Akash} alt="Akash Institute of Medical Sciences & Research Centre" />
                  <div className="card-body">
                    <h5 className="card-title">Akash Institute of Medical Sciences & Research Centre
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Yenepoya Medical College (YMC)" />
                  <div className="card-body">
                    <h5 className="card-title">Yenepoya Medical College (YMC)

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Mangalore, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={SDM} alt="SDM College of Medical Sciences & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title">SDM College of Medical Sciences & Hospital


                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Dharwad, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={AI} alt="Al-Ameen Medical College (AMCH)" />
                  <div className="card-body">
                    <h5 className="card-title">Al-Ameen Medical College (AMCH)


                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bijapur, Karnataka
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>





            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f600 ' style={{ fontSize: 24 }}>
            & Many More!</div>


        </div>
      </section >

      <section className=" p-0 container-fluid d-flex align-items-center pt-3 pb-0 " id="Contact">
        <div className=" bgimage text-center d-flex justify-content-center align-items imgh" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${banner})` }}>
          <div className='dd '><h2 className='bgblack p-3 h2-heading'>Looking for MBBS Admission <br className='d-block d-md-none' /> Guidance to get into the <br className=' d-none d-md-block' /> Best <br className='d-block d-md-none' /> MBBS Colleges in Karnataka?</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5" onClick={handleShow}>
              Enquire Now!
            </Button>
          </div>
        </div>


      </section>

      <footer className='pb-3  bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <a href="/"><img src={Defaultlogo} className="imgfooter" alt="bangalorestudy " /></a>
              </div>

              <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
                {" "}
                © 2025 Bangalorestudy.com. All Rights Reserved.
              </p>

              </div>


            </div>

          </div>
          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100  justify-content-end padding-footer" >
              <div><p className=" font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696(Toll
                    Free)
                  </a>
                </div>
                <p >
                  <span style={{ fontSize: 20, fontWeight: 600 }}> Dubai </span> :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                  </a>
                </p>



              </div>
              <div className="text-center" >
                <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5 d-flex'>
                  <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                    <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                    <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                  >
                    {" "}
                    <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://instagram.com/bangalorestudy ">
                    <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/ "
                  >
                    {" "}
                    <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                    <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                  </a>
                </p>

              </div>
            </div>

          </div>




        </div>




        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-4 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <a href="/"> <img src={Defaultlogo} className="imgfooter" alt="bangalorestudy " /></a>
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex pt-0 mt-0">
            <div className="app-unit col-md-7  col-md-5 ">
              {/* <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/ "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div> */}
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}<br className='d-block' />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
              <div className="text-center" >
                <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5 d-flex'>
                  <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                    <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                    <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                  >
                    {" "}
                    <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://instagram.com/bangalorestudy ">
                    <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/ "
                  >
                    {" "}
                    <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                    <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                  </a>
                </p>

              </div>
            </div>
            <p className="pe-4">
              {" "}
              © 2025 Bangalorestudy.com. All Rights Reserved.
            </p>

          </div>
        </div>
      </footer>
      {/* <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="bi bi-whatsapp my-float " />
      </a> */}

      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 8,
          right: 24,
          // backgroundColor: "#25d366",
          // color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          // boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
        <img src={whtsp} alt='whtsp logo' style={{ width: "66px" }}></img>
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>{textmodal}</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>

            <fieldset className="form-group group_field">
              <input
                name="neetrank"
                type="number"
                id="neetrank"
                // min={0}
                // required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.neetrank} onChange={set('neetrank')}
              />
            </fieldset>

            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andaman & Nicobar ">Andaman & Nicobar</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli ">Dadra and Nagar Haveli</option>
                  <option value="Daman & Diu ">Daman & Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu & Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Puducherry ">Puducherry</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="West Bengal ">West Bengal</option>

                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>
    </>
  );
};

export default BscNursing;